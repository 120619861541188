import { TemperatureRecordingsModel } from './temperature-recordings.model';
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SetBackUrl, SetShipment, SetTemperatureRecordingInformation } from './temperature-recordings.actions';

@State<TemperatureRecordingsModel>({
  name: 'temperatureRecordings',
})
@Injectable()
export class TemperatureRecordingsState {
  @Action(SetShipment)
  public SetShipment(
    ctx: StateContext<TemperatureRecordingsModel>,
    { shipment, name }: TemperatureRecordingsModel,
  ): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      shipment,
      name,
    });
  }

  @Action(SetTemperatureRecordingInformation)
  public SetTemperatureRecordingInformation(
    ctx: StateContext<TemperatureRecordingsModel>,
    { information }: TemperatureRecordingsModel,
  ): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      information,
    });
  }

  @Action(SetBackUrl)
  public setBackUrl(ctx: StateContext<TemperatureRecordingsModel>, { backUrl }: TemperatureRecordingsModel): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      backUrl,
    });
  }
}
